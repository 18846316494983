import { IContainer } from 'types/ContentfulTypes';
import ContentfulModelMapper from '../../ContentfulModelMapper/ContentfulModelMapper';
import { StyledContainer, StyledFitContentWrapper } from './ContentfulContainer.styled';
import { CONTENTFUL_TYPES } from '../types';
import { CONTENT_TYPE } from 'types/ContentfulTypes';

interface ContentfulContainerProps {
  content: IContainer;
}

const NOT_FULL_WIDTH_COMPONENTS: Array<CONTENT_TYPE> = [CONTENTFUL_TYPES.CALL_TO_ACTION];

const ContentfulContainer = ({ content }: ContentfulContainerProps) => {
  const contentFields = content.fields.content;
  const anchorText = content.fields.anchorText;
  return (
    <StyledContainer id={anchorText}>
      {contentFields?.map((entry) => {
        const isNotFullWidth = NOT_FULL_WIDTH_COMPONENTS.includes(entry.sys.contentType.sys.id as CONTENT_TYPE);

        if (isNotFullWidth) {
          return (
            <StyledFitContentWrapper key={entry.sys.id}>
              <ContentfulModelMapper content={entry} />
            </StyledFitContentWrapper>
          );
        }
        return <ContentfulModelMapper key={entry.sys.id} content={entry} />;
      })}
    </StyledContainer>
  );
};
export default ContentfulContainer;
